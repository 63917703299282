/**
 * Single registry for all data-cy tags used within phone-sheet & consumed dependencies
 */
module.exports = {
  // #region - Navbar
  NAV_BAR: 'nav-bar',
  // #endregion - Navbar

  // #region - Call list
  HEADER: {
    MENU_DROPDOWN: 'menu-dropdown',
    MENU_ITEM: 'menu-item',
    STATUS_ABBR: 'menu-status-abbr',
    STATUS_DRAG_HANDLE: 'status-dragHandle',
    SETTINGS: {
      MODAL_HIDE_FUTURE_CALLS: 'settings-modal-hide-future-calls',
      MODAL_SAVE: 'settings-modal-save',
      MODAL_STATUS_TEXT_BOX: 'settings-status-text-box',
      MODAL_CLOSE: 'settings-modal-close',
    },
  },
  SEARCH: {
    SEARCH_BAR: 'search-bar',
  },
  FILTERS: {
    FILTERS_LIST: 'filters-list',
    STATUS_CHECKBOX: 'filter-checkbox',
    FILTER_PILL: 'filter-pill',
    SIDE_PANEL_PILL: 'side-panel-pill',
    STAR_CHECKBOX: 'star-checkbox',
    RESET: 'filters-reset',
  },
  CALL_LIST: {
    CALL_LIST: 'call-list',
    ADD_CALL: 'add-call',
    STATUS_DIVIDER: 'status-divider',
    STATUS_SECTION: 'status-section',
    LOAD_MORE: 'load-more',
    BULK_UPDATE_STATUS: 'bulk-update-status',
    BULK_DELETE: 'bulk-delete',
    BULK_DELETE_CONFIRMATION: 'bulk-delete-confirmation',
  },
  CALL_ROW: {
    CALL_ROW: 'call-row',
    STAR: 'call-row-star',
    NO_STAR: 'call-row-no-star',
    NAME: 'call-row-name',
    STATUS: 'call-row-status',
    CONTACT: 'call-row-contact',
    CONTACT_OPTIONS: 'call-row-contact-options',
    COMPANY: 'call-row-company',
    NOTES: 'call-row-notes',
    NOTES_INPUT: 'call-row-notes-input',
    DATE: 'call-row-date',
    DATE_DAY_BUTTON: 'call-row-date-day-button',
    DATE_INNER_LABEL: 'call-row-date-inner-label',
    CALL_HISTORY: 'call-row-call-history',
    DELETE_CALL: 'call-row-delete-call',
  },
  CALL_FORM: {
    CALL_FORM: 'call-form',
    STATUS_TEXT_BOX: 'call-form-status-text-box',
    NAME_TEXT_BOX: 'call-form-name-text-box',
    TYPE_TEXT_BOX: 'call-form-type-text-box',
    TYPE_SELECT_BOX: 'call-form-type-select-box',
    COUNTRY_TEXT_BOX: 'call-form-country-text-box',
    PHONE_TEXT_BOX: 'call-form-phone-text-box',
    NORMAL_TEXT_BOX: 'call-form-normal-text-box',
    EXTENSION_TEXT_BOX: 'call-form-extension-text-box',
    DATE_TEXT_BOX: 'call-form-date-text-box',
    NOTES_TEXT_BOX: 'call-form-notes-text-box',
    CANCEL_BUTTON: 'call-form-cancel-button',
    ADD_TODO_BUTTON: 'call-form-add-button',
    CHECKMARK_BUTTON: 'call-form-checkmark-button',
  },
  CONTACT_INPUT: {
    EXISTING_CONTACT: 'call-row-existing-contact',
    ADD_CONTACT: 'call-row-add-contact',
    CONTACT_TYPE: 'call-row-contact-type',
    PHONE_NUMBER_INPUT: 'phone-number-input',
  },
  EXISTING_CONTACT_MODAL: {
    MODAL: 'existing-contact-modal',
    BUTTON_USE_EXISTING: 'existing-contact-modal-use-existing-button',
    BUTTON_CREATE_NEW: 'existing-contact-modal-create-new-button',
  },
  DELETE_CALL_MODAL: {
    MODAL: 'delete-call-modal',
    BUTTON_CONFIRM: 'delete-call-modal-confirm-button',
    BUTTON_CANCEL: 'delete-call-modal-cancel-button',
  },
  CALL_HISTORY_TABLE_MODAL: {
    MODAL: 'history-table-modal',
    ROW: 'history-table-modal-row',
  },
  // #endregion - Call list
};
