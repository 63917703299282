import styled, { css } from 'styled-components/macro';
import * as sizes from '../../../styles/sizes';

export const GridContainer = styled.div`
  display: grid;
  grid-template-rows: min-content min-content 50px minmax(0px, max-content) auto;

  ${({ isCollapsed }) =>
    isCollapsed
      ? css`
          grid-template-columns: 250px minmax(400px, auto);

          @media ${sizes.mediumBreakpoint} {
            grid-template-columns: 250px minmax(400px, auto);
          }

          @media ${sizes.mobileBreakpoint} {
            grid-template-columns: 250px minmax(400px, auto);
          }
        `
      : css`
          grid-template-columns: 5% minmax(400px, auto);

          @media ${sizes.mediumBreakpoint} {
            grid-template-columns: 45px minmax(400px, auto);
          }

          @media ${sizes.mobileBreakpoint} {
            grid-template-columns: 0px minmax(400px, auto);
          }
        `};
`;

export const BrandingGridCell = styled.div`
  grid-column-start: 1;
  grid-column-end: span 2;
`;

export const SearchBarPlaceholderGridCell = styled.div`
  grid-column-start: 1;
  grid-column-end: 2;
  background-color: #fff;
`;

export const SearchBarGridCell = styled.div`
  grid-column-start: 2;
  grid-column-end: 3;
  padding-right: 5%;
  background-color: #fff;

  @media ${sizes.mediumBreakpoint} {
    padding-right: 45px;
  }

  @media ${sizes.mobileBreakpoint} {
    padding-right: 15px;
  }
`;

export const ButtonContainerGridCell = styled.div`
  grid-row-start: 3;
  grid-row-end: 4;
  grid-column-start: 2;
  grid-column-end: 3;
  margin-right: 5%;
  margin-top: 10px;
  align-self: center;

  @media ${sizes.mediumBreakpoint} {
    margin-right: 45px;
  }

  @media ${sizes.mobileBreakpoint} {
    margin-left: 15px;
    margin-right: 15px;
  }
`;

export const ButtonContainer = styled.div`
  display: inline-grid;
  grid-template-columns: min-content min-content auto auto;
  grid-template-areas: 'expand reset filters addCall';
  align-items: center;
  width: 100%;
`;

export const FilterContainer = styled.div`
  grid-area: filters;
`;

export const ResultCountContainer = styled.div`
  font-size: 12px;
  font-weight: 600;
`;

export const PillsContainer = styled.div`
  display: flex;
  align-items: center;
  height: 26px;
  margin-bottom: 8px;
`;

export const ResetButton = styled.div`
  cursor: pointer;
  padding-right: 15px;
  font-size: 12px;
`;

export const Pills = styled.div`
  justify-self: flex-start;
`;

export const AddCall = styled.div`
  grid-area: addCall;
  justify-self: flex-end;
  cursor: pointer;
`;

export const CallFormGridCell = styled.div`
  grid-row-start: 4;
  grid-row-end: 5;
  grid-column-start: 2;
  grid-column-end: 3;
  margin-right: 5%;
  z-index: 1;

  @media ${sizes.mediumBreakpoint} {
    margin-right: 45px;
  }

  @media ${sizes.mobileBreakpoint} {
    margin-left: 15px;
    margin-right: 15px;
  }
`;

export const FilterSelectorGridCell = styled.div`
  grid-row-start: 3;
  grid-row-end: 6;
  grid-column-start: 1;
  grid-column-end: 2;
  margin-left: 25px;
`;

export const CallListGridCell = styled.div`
  grid-row-start: 5;
  grid-row-end: 6;
  grid-column-start: 2;
  grid-column-end: 3;
  margin-right: 5%;

  @media ${sizes.mediumBreakpoint} {
    margin-right: 45px;
  }

  @media ${sizes.mobileBreakpoint} {
    margin-right: 0px;
  }
`;
